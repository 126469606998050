.plot {
    fill: transparent;
    -webkit-transition: all 150ms ease;
    -o-transition: all 150ms ease;
    transition: all 150ms ease;
    cursor: pointer;
}
.plot.green:hover {
    fill:green;
    opacity: 0.75;
}
.plot.gold:hover {
    fill:gold;
	opacity: 0.75;
}
.plot.orange {
    fill:orange;
	opacity: 0.75;
}
.plot.red {
    fill:red;
    opacity: 0.75;
    cursor: not-allowed;
}

.plot.red:hover {
    fill:red;
    opacity: 0.75;
    cursor: not-allowed;
}

.plot.orange:hover {
    fill:orange;
	opacity: 0.75;
}