.pb100{
    padding-bottom: 100px;
}

@media (max-width: 551px)
{
	.pb100{
		padding-bottom: 50px;
	}
}
.logo{
    z-index: 9999;
}
.dotResa{
    font-size: 23px;
    transform: translateY(2px);
    display: inline-block;
}

/* menu effect */
.menu-enter {
    transition-duration: .5s;
    width: 0px;
}
  
.menu-enter.menu-enter-active {
    width: 280px; 
}
  
.menu-leave {
    width: 280px; 
    transition-duration: .5s;
}
  
.menu-leave.menu-leave-active {
    width: 0px;
    border-right: 0px;
}


.menu-enter .link  {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    transition-duration: 1.2s;
}
  
.menu-enter.menu-enter-active .link {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
  
.menu-leave .link{
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition-duration: 0s;
}
  
.menu-leave.menu-leave-active .link {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
}

/* slide effect */

.leftSlide-enter {
    transition-duration: 1.5s;
    transform: translateX(-200%);
}

  
.leftSlide-enter.leftSlide-enter-active {
    transform: translateX(0);
}
  
.leftSlide-leave {
    transform: translateX(0);
    transition-duration: .5s;
}
  
.leftSlide-leave.leftSlide-leave-active {
    transform: translateX(-200%);
}


.rightSlide-enter {
    transition-duration: 1.5s;
    transform: translateX(200%);
}

  
.rightSlide-enter.rightSlide-enter-active {
    transform: translateX(0);
}
  
.rightSlide-leave {
    transform: translateX(0);
    transition-duration: .5s;
}
  
.rightSlide-leave.rightSlide-leave-active {
    transform: translateX(200%);
}