.pb100{
    padding-bottom: 100px;
}

@media (max-width: 551px)
{
	.pb100{
		padding-bottom: 50px;
	}
}
.logo{
    z-index: 9999;
}
.dotResa{
    font-size: 23px;
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
    display: inline-block;
}

/* menu effect */
.menu-enter {
    -webkit-transition-duration: .5s;
            transition-duration: .5s;
    width: 0px;
}
  
.menu-enter.menu-enter-active {
    width: 280px; 
}
  
.menu-leave {
    width: 280px; 
    -webkit-transition-duration: .5s; 
            transition-duration: .5s;
}
  
.menu-leave.menu-leave-active {
    width: 0px;
    border-right: 0px;
}


.menu-enter .link  {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transition-duration: 1.2s;
            transition-duration: 1.2s;
}
  
.menu-enter.menu-enter-active .link {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
  
.menu-leave .link{
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
}
  
.menu-leave.menu-leave-active .link {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
}

/* slide effect */

.leftSlide-enter {
    -webkit-transition-duration: 1.5s;
            transition-duration: 1.5s;
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
}

  
.leftSlide-enter.leftSlide-enter-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
  
.leftSlide-leave {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition-duration: .5s;
            transition-duration: .5s;
}
  
.leftSlide-leave.leftSlide-leave-active {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
}


.rightSlide-enter {
    -webkit-transition-duration: 1.5s;
            transition-duration: 1.5s;
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
}

  
.rightSlide-enter.rightSlide-enter-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
  
.rightSlide-leave {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition-duration: .5s;
            transition-duration: .5s;
}
  
.rightSlide-leave.rightSlide-leave-active {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
}
.plot {
    fill: transparent;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    cursor: pointer;
}
.plot.green:hover {
    fill:green;
    opacity: 0.75;
}
.plot.gold:hover {
    fill:gold;
	opacity: 0.75;
}
.plot.orange {
    fill:orange;
	opacity: 0.75;
}
.plot.red {
    fill:red;
    opacity: 0.75;
    cursor: not-allowed;
}

.plot.red:hover {
    fill:red;
    opacity: 0.75;
    cursor: not-allowed;
}

.plot.orange:hover {
    fill:orange;
	opacity: 0.75;
}
